<template>
  <div id="index" :class="[this.$isua.isPc ? 'wmstyle' : '']">
    <router-view />
  </div>
</template>
<script>
// eslint-disable-next-line

export default {
  data() {
    return {
      isusePc: "",
    };
  },
  mounted() {
    // eslint-disable-next-line
    // // eslint-disable-next-line
    // console.log(navigator.userAgent)
    // if (os.isAndroid || os.isPhone) {
    //   // eslint-disable-next-line
    //   console.log('手机')
    // } else if (os.isTablet) {
    //   // eslint-disable-next-line
    //   console.log('平板')
    // } else if (os.isPc) {
    //   // eslint-disable-next-line
    //   console.log('电脑')
    // }
  },
  methods: {
    isua() {
      let ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
      return (this.isusePc = {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc,
      });
    },
  },
};
</script>
<style>
@font-face {
  font-family: "SukhumvitSet";
  src: url("./assets/fonts/SukhumvitSet-Light.ttf");
}
@font-face {
  font-family: "SourceHanSansCN-Light";
  src: url("./assets/fonts/SourceHanSansCN-Light.ttf");
}
@font-face {
  font-family: "SourceHanSansCN-Normal";
  src: url("./assets/fonts/SourceHanSansCN-Normal.ttf");
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}

.wmstyle {
  width: calc(100vw - 50vw);
  margin: 0 auto;
  height: 100%;
}
.wmstyle .page-register {
  width: 100% !important;
}
.wmstyle .page-info {
  width: 100% !important;
}
.wmstyle .page-info .coupon-info {
  width: 100% !important;
}
.wmstyle .page-info .coupon-info .fixed-footer {
  width: 100% !important;
}
.wmstyle .page-index {
  width: 100% !important;
}
.wmstyle .page-register .app-form {
  width: calc(100% - 3.8%) !important;
}
.wmstyle .page-register .form-buttons {
  width: calc(100% - 53%) !important;
}
.wmstyle .page-register .form-buttons .button-blue {
  width: 100% !important;
}
.wmstyle .page-quest {
  width: 100% !important;
}
.wmstyle .page-quest .app-form {
  width: calc(100% - 5%) !important;
}
.wmstyle .page-quest .form-buttons {
  width: calc(100% - 53%) !important;
}
.wmstyle .page-quest .form-buttons .button-blue {
  width: 100% !important;
}
#index {
  height: 100%;
}
</style>
