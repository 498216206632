import Vue from 'vue';
import Router from 'vue-router';


Vue.use(Router);

const routes = [{
		path: '*',
		redirect: '/index'
	},
	/**
	 * 新版路由 
	 */
	{
		name: 'index',
		path: '/index',
		component: () => import('./views/index/index'),
		meta: {
			title: 'Simplus Membership'
		}
	},
	{
		name: 'lpindex',
		path: '/lp/202404',
		component: () => import('./views/lp/index'),
		meta: {
			title: 'Simplus Social Account'
		}
	},
	{
		name: 'OW',
		path: '/OW',
		component: () => import('./views/index/index'),
		meta: {
			title: 'Simplus Membership'
		}
	},
	{
		name: 'CRAD',
		path: '/CRAD',
		component: () => import('./views/index/index'),
		meta: {
			title: 'Simplus Membership'
		}
	},
	{
		name: 'register',
		path: '/register',
		component: () => import('./views/index/register'),
		meta: {
			title: 'Register'
		}
	}, {
		name: 'quest',
		path: '/quest',
		component: () => import('./views/index/quest'),
		meta: {
			title: 'Quest'
		}
	}, {
		name: 'info',
		path: '/info',
		component: () => import('./views/index/info'),
		meta: {
			title: 'Info'
		}
	}, {
		name: 'guide',
		path: '/guide',
		component: () => import('./views/index/guide'),
		meta: {
			title: 'Guide'
		}
	}, {
		name: 'form',
		path: '/form',
		component: () => import('./views/index/form'),
		meta: {
			title: 'Form'
		}
	}, {
		name: 'form2',
		path: '/form2',
		component: () => import('./views/index/form2'),
		meta: {
			title: 'Member Exclusive Activity'
		}
	}, {
		name: 'policy',
		path: '/policy',
		component: () => import('./views/index/policy'),
		meta: {
			title: 'policy'
		}
	}, {
		name: 'terms',
		path: '/terms',
		component: () => import('./views/index/terms'),
		meta: {
			title: 'terms'
		}
	},
	{
		name: 'thform',
		path: '/thform',
		component: () => import('./views/index/thform'),
		meta: {
			title: 'Simplus NPS Form'
		}
		
	},
	{
		name: 'live',
		path: '/live',
		component: () => import('./views/live/index'),
		meta: {
			title: 'Simplus New Year Gift'
		}
		
	},
	//这里都是 旧版的地址  全部停用了
	// ,
	// {
	// 	name: 'index',
	// 	path: '/index',
	// 	component: () => import('./view/page/index'),
	// 	meta: {
	// 		title: 'Simplus'
	// 	}
	// },
	// {
	// 	name: 'registration',
	// 	path: '/registration',
	// 	component: () => import('./view/registration/index'),
	// 	meta: {
	// 		title: 'Simplus Membership Resgistration'
	// 	}
	// },

	// {
	// 	name: 'login',
	// 	path: '/login',
	// 	component: () => import('./view/user/verification'),
	// 	meta: {
	// 		title: 'Login'
	// 	}
	// },
	// {
	// 	name: 'userinfo',
	// 	path: '/userinfo',
	// 	component: () => import('./view/user/index'),
	// 	meta: {
	// 		title: 'Member Centre'
	// 	}
	// },
];

// add route path
routes.forEach(route => {
	route.path = route.path || '/' + (route.name || '');
});



const router = new Router({
	base: '/en',
	routes
});

router.beforeEach((to, from, next) => {
	const title = to.meta && to.meta.title;
	if (title) {
		document.title = title;
	}
	next();
});



export {
	router
};
