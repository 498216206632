import Vue from 'vue';
import App from './App';
import axios from 'axios'
import VueAxios from 'vue-axios';
import {
	router
} from './router';
import {
	utils
} from './util/axios';
import './util/rem'

const isua = () => {
	let ua = navigator.userAgent,
		isWindowsPhone = /(?:Windows Phone)/.test(ua),
		isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
		isAndroid = /(?:Android)/.test(ua),
		isFireFox = /(?:Firefox)/.test(ua),
		isTablet =
		/(?:iPad|PlayBook)/.test(ua) ||
		(isAndroid && !/(?:Mobile)/.test(ua)) ||
		(isFireFox && /(?:Tablet)/.test(ua)),
		isPhone = /(?:iPhone)/.test(ua) && !isTablet,
		isPc = !isPhone && !isAndroid && !isSymbian
	return {
		isTablet: isTablet,
		isPhone: isPhone,
		isAndroid: isAndroid,
		isPc: isPc,
	}
}
let isUa = isua()
Vue.prototype.$isua = isUa;
window.onresize = function() {
	isUa = isua()
	// eslint-disable-next-line
	Vue.prototype.$isua =  isUa  ;  
}
// axios.defaults.baseURL = 'https://test.yaoyao-inc.com'
// axios.defaults.baseURL = 'http://192.168.10.240:8080'
// axios.defaults.baseURL = 'https://h5.yaoyao-inc.com'
// axios.defaults.baseURL = "http://47.100.193.68:8000"

axios.interceptors.request.use(config => {
	// 在发送请求前做一些事
	if (localStorage.getItem('LoginToken')) {
		config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('LoginToken');
	}
	return config
}, err => {
	// 在请求错误时做的事儿
	return Promise.reject(err)
})

if (isUa.isPc) {
	const scale = document.documentElement.clientWidth / 375
	// 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
	document.documentElement.style.fontSize = 16 * Math.min(scale, 1) + 'px'
	// 改变窗口大小时重新设置 rem
	window.onresize = function() {
		const scale = document.documentElement.clientWidth / 375
		// 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
		document.documentElement.style.fontSize = 16 * Math.min(scale, 1) + 'px'

	}
}

Vue.prototype.$utils = utils;


router.beforeEach((to, from, next) => {
	// eslint-disable-next-line
	// localStorage.setItem('EnisUserinfo', 'true');
	console.log(to.name,'to.name.');
	if (to.name === 'index' || to.name ==  'lpindex') {
		next()
	} else {
		const EnIsLogin = localStorage.getItem('EnisUserinfo'); //获取本地存储的登陆信息
		//  // eslint-disable-next-line
		// console.log(EnIsLogin==='true')
		if (EnIsLogin === 'true' && to.name === 'login') {
			// eslint-disable-next-line
			console.log(EnIsLogin, to.name === 'login')
			if (to.name === 'login' && to.name != 'userinfo') {
				next()
			} else {
				next('/login')
			}
		} else {
			next()
		}

	}

})
Vue.use(VueAxios, axios)
new Vue({
	router,
	el: '#index',
	render: h => h(App)
});
