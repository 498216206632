// import axios from 'axios'
// import { Toast } from 'vant';
 
 
// // axios.defaults.withCredentials = false;
// // axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';//配置请求头
 
// // http request拦截器 添加一个请求拦截器
// axios.interceptors.request.use(function (config) {
//  return config;
// }, function (error) {
//     Toast.fail('请求超时');
//     // Do something with request error
//     return Promise.reject(error);
// });
 
// // 添加一个响应拦截器
// axios.interceptors.response.use(function (response) {
//     return response;
// }, function (error) {
//     Toast.fail("服务器连接失败");
//     return Promise.reject(error);
// })
 
// let base = 'http://h5.yaoyao-inc.com:8096';
 
// //通用方法
// export const POST = (url, params) => {
//     if(url!= 'http://47.100.193.68:8096/file'){
//         return axios.post(`${url}`,params).then(res => res.data)
//     }else{
//         return axios.post(`${url}`,params).then(res => res.data)
//     }
    
// }
 
// export const GET = (url, params) => {
   
//     return axios.get(`${url}`, { params: params }).then(res => res.data)
// }
 
// export const PUT = (url, params) => {
//     return axios.put(`${base}${url}`, params).then(res => res.data)
// }
 
// export const DELETE = (url, params) => {
//     return axios.delete(`${base}${url}`, { params: params }).then(res => res.data)
// }
 
// export const PATCH = (url, params) => {
//     return axios.patch(`${base}${url}`, params).then(res => res.data)
// }


export const utils = {
    /**
     * 对象转url参数
     * @param {*} data
     * @param {*} isPrefix
     */
    queryParams (data, isPrefix = false) {
      let prefix = isPrefix ? '?' : ''
      let _result = []
      for (let key in data) {
        let value = data[key]
        // 去掉为空的参数
        if (['', undefined, null].includes(value)) {
          continue
        }
        if (value.constructor === Array) {
          value.forEach(_value => {
            _result.push(encodeURIComponent(key) + '[]=' + encodeURIComponent(_value))
          })
        } else {
          _result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
        }
      }
  
      return _result.length ? prefix + _result.join('&') : ''
    }
}